@import "styles/_variables.scss";

.container {
  background-color: $greyBackground;
  padding: 80px 0;
  margin: 80px 0 0;

  @media (max-width: $tabletPoint) {
    padding: 56px 0;
    margin: 56px 0 0;
  }

  @media (max-width: $mobilePoint) {
    padding: 48px 0;
    margin: 33px 0 0;
  }
}
