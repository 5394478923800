@import "styles/_variables.scss";

.section {
  color: $blogMainText;
  font-family: $fontFamily;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media(max-width: $tabletPoint) {
    max-width: 100%;
    padding: 0 22px;
  }

  &__head {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 32px;
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    max-width: 732px;
  }
}

.block {
  border-top: 1px solid $faqLinesColor;
  cursor: pointer;
  position: relative;
  transition: before 0.4s;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__last {
    border-bottom: 1px solid $faqLinesColor;
  }

  &__wrapper {

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      z-index: 0;
    }

    @media (min-width: $mobilePoint) {
      &:hover::before {
        height: 100%;
      }
    }

    @media (max-width: $mobilePoint) {
      &:active::before {
          height: 100%;
          transition: 0s;
      }
    }

    &.grey::before {
      background-color: $faqGreyHoverColor;
    }

    &.white::before {
      background-color: $white;
    }
  }

  &__question {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 32px 20px;
    width: 100%;
    z-index: 2;

    @media (max-width: $mobilePoint) {
      padding: 24px 20px 24px 2px;
    }
  }

  &__number {
    border-radius: 30px;
    border: 1px solid $borderColorFAQ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding: 10px;
    margin-right: 20px;
    width: 48px;
    height: 48px;
    z-index: 2;

    @media (max-width: $mobilePoint) {
      display: none;
    }
  }

  &__title {
    display: inline-block;
    margin-right: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 42px;
    z-index: 2;

    @media (max-width: $mobilePoint) {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__icon {
    margin-left: auto;
    transition: transform 0.2s linear;
    transform: rotate(0deg);
    z-index: 2;
  }

  &__icon.opened {
    transition: transform 0.2s linear;
    transform: rotate(45deg);
  }

  &__answer {
    max-width: 940px;
    margin: -20px 88px 0;
    padding-bottom: 32px;
    z-index: 2;

    @media (max-width: $mobilePoint) {
      margin: -10px 20px 0 0;
      padding-bottom: 24px;
    }

    & ul, & ol {
      margin-bottom: 0;
      padding-left: 20px;
      margin-left: 5px;
    }

    &_item, & p, & li {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      position: relative;
      margin: 0;
      z-index: 2;

      @media (max-width: $mobilePoint) {
        font-size: 16px;
      }
    }

    & a {
      text-decoration: underline;
      color: blue;
    }

    &_item:not(:first-of-type), & p:not(:first-of-type), & li:not(:first-of-type) {
      margin-top: 16px;
    }
  }
}
